import { render, staticRenderFns } from "./changeFaceVideo.vue?vue&type=template&id=de084fc8&scoped=true&"
import script from "./changeFaceVideo.vue?vue&type=script&lang=js&"
export * from "./changeFaceVideo.vue?vue&type=script&lang=js&"
import style0 from "./changeFaceVideo.vue?vue&type=style&index=0&id=de084fc8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de084fc8",
  null
  
)

export default component.exports